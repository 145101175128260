<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <ReportViewer v-if="reppath" v-bind:json_data = jsondata v-bind:reportfilename="filename" v-bind:report_url="reppath"></ReportViewer>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <FocusTrap>
        <vmodal name="filter_window" :delay="100" :resizable="true" :width="575" height="auto" focusTrap="true" @before-open="beforeOpen" @before-close="beforeClose" @opened="afterfilterwindow_visible">
          <div class="p-1">
            <div class="card-header text-warning-600 p-1 bg-teal-800"> <h3>Department Wise Books</h3> </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Acquisition</label>
              <div class="col-md-4">
                <input  ref="txtFrom" class="form-control" type="date"  style="padding: 0px;" v-model="from_date">
              </div>
              <label class="col-md-2 col-form-label font-weight-semibold">Upto</label>
              <div class="col-md-4">
                <input class="form-control  text-center" type="date" v-model="upto_date">
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Author</label>
              <div class="col-md-10">
                <select  ref="cmbAuthor" class="form-control" v-model="author_id">
                  <option value="0" selected>All</option>
                  <option v-for="author in authors" :value="author.id">{{author.name}}</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Publisher</label>
              <div class="col-md-10">
                <select  ref="cmbPublisher" class="form-control" v-model="publisher_id">
                  <option value="0" selected>All</option>
                  <option v-for="ledger in publishers" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Department</label>
              <div class="col-md-10">
                <select  ref="cmbPublisher" class="form-control" v-model="department_id">
                  <option value="0" selected>All</option>
                  <option v-for="ledger in departments" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Category</label>
              <div class="col-md-10">
                <select  ref="cmbPublisher" class="form-control" v-model="category_id">
                  <option value="0" selected>All</option>
                  <option v-for="ledger in categories" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row align-self-end">
              <div class="col-md-12 text-right">
                <button class="btn btn-outline-success" @click="loadData">Show Report <i class="icon-printer ml-2" style="color: green;"></i> </button>
              </div>

            </div>

          </div>
        </vmodal>
      </FocusTrap>

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment';
import {userService} from '@/store/auth-header.js'
import ReportViewer from "@/views/tools/ReportViewerByJson";
import { store } from '@/store/store.js'

export default {
  name: 'DeptWiseBooks',
  components: {
    ReportViewer,
  },
  store,
  data()  {
    return {
      reppath: '',
      filename: 'deaprtment_wise_books.mrt',
      ason_date:  moment().format('YYYY-MM-DD'),
      jsondata : {},
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      authors: [],
      publishers: [],
      categories : [],
      departments : [],
      author_id : 0,
      publisher_id : 0,
      category_id : 0,
      department_id : 0,
    }
  },
  mounted() {
    const self = this;
    self.$modal.show('filter_window');
    self.loadFilterData();
  },
  methods: {
    afterfilterwindow_visible () {
      this.$data.reppath = '';
      this.$refs.txtFrom.focus();
    },
    showFilterWindow () {
      this.$modal.show('filter_window');
    },
    loadFilterData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: "cors",
        headers: userService.authHeader()
      };

      self.$data.authors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/authors`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.authors = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      });

      self.$data.publishers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/publishers/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if ( _.isArray(resp.data)) {
            self.$data.publishers = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      }).catch(function (err) {

      });

      self.$data.departments = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/departments/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if ( _.isArray(resp.data)) {
            self.$data.departments = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      }).catch(function (err) {
      });

      self.$data.categories = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/categories/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if ( _.isArray(resp.data)) {
            self.$data.categories = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      }).catch(function (err) {
      });
    },
    loadData(){
      const self = this;
      try {
        self.$modal.hide('filter_window');

        const requestOptions = {
          method: 'GET',
          mode: "cors",
          headers: userService.authHeader()
        };

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        let filters = `${self.$data.from_date}&upto=${self.$data.upto_date}&author_id=${self.$data.author_id}&publisher_id=${self.$data.publisher_id}&category_id=${self.$data.category_id}&department_id=${self.$data.department_id}`;

        console.log(`${process.env.VUE_APP_ROOT_API}api/lms/reports/book?${filters}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/reports/book?${filters}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.jsondata = resp;
              self.$data.reppath = `${process.env.VUE_APP_ROOT_API}api/lms/books/report?${filters}`;
            }
          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#mycard').unblock();
        });

      }catch (e) {
        alert(e);
      }

    },
  }
}
</script>

<style scoped>

</style>
